<template lang="pug">
  div.reservation-options
    p.reservation-options__title Reservation options
    template(v-if="showLicenses")
      div.reservation-options__item(
        v-for="(item, index) in LICENSE_TYPES"
        :key="item.slug"
      )
        v-checkbox(
          :ref="'option-' + item.slug"
          :input-value="isOptionSelected(item.slug)"
          :label="item.name"
          :readonly="loading || saveLoading"
          :disabled="loading || saveLoading"
          hide-details
          color="success"
          @change="openProductChangeConfirmation(item.slug, $event)"
        ).mt-0
    confirmation-dialog(
      v-if="productChangeConfirmation && productChangeConfirmation.slug"
      v-model="confirmationModalShowing"
      :title="confirmationModalTitle"
      @okBtnClicked="onChanged(productChangeConfirmation.slug, productChangeConfirmation.value)"
      @failBtnClicked="closeProductChangeConfirmation(true)"
      @click:outside="closeProductChangeConfirmation(true)"
    )
</template>

<script>
import {LICENSE_TYPES} from "@/util/const";

export default {
  inject: ['svc'],

  data() {
    return {
      LICENSE_TYPES,
      loading: false,
      saveLoading: false,
      showLicenses: true,
      optionsSelected: null,
      confirmationModalShowing: false,
      productChangeConfirmation: {
        value: null,
        slug: null,
      }
    }
  },

  computed: {
    confirmationModalTitle() {
      if(!this.productChangeConfirmation.slug) {
        return '';
      }
      const option = this.LICENSE_TYPES.find(e => e.slug === this.productChangeConfirmation.slug);
      return `Are you sure to ${this.productChangeConfirmation.value ? 'enable' : 'disable'} ${option.name} reservation option?`;
    }
  },

  mounted() {
    this.loadData()
  },

  watch: {
    '$route.query.course_id'() {
      this.loadData()
    }
  },

  methods: {
    isOptionSelected(option) {
      return !!this.optionsSelected && this.optionsSelected.includes(option);
    },
    openProductChangeConfirmation(slug, value) {
      this.confirmationModalShowing = true;
      this.productChangeConfirmation.value = value;
      this.productChangeConfirmation.slug = slug;
    },
    async closeProductChangeConfirmation(reset = false) {
      if(reset) {
        this.showLicenses = false;
        await this.$nextTick();
        this.showLicenses = true;
      }
      this.confirmationModalShowing = false;
      this.productChangeConfirmation.value = null;
      this.productChangeConfirmation.slug = null;
    },
    onChanged(slug, value) {
      if (value) {
        this.optionsSelected.push(slug);
      } else {
        this.optionsSelected = this.optionsSelected.filter(e => e !== slug);
      }
      this.updateValue(this.optionsSelected);
    },
    async loadData() {
      if (this.$route.query.course_id) {
        try {
          this.loading = true;
          const courseLoaded = await this.svc.getCourse(this.$route.query.course_id);
          this.optionsSelected = courseLoaded.reservable_license_types || null;
          this.loading = false;
        } catch (e) {
          console.error('Error loading course', e);
          this.loading = false;
        }
      }
    },

    async updateValue(value) {
      if (this.saveLoading) return;
      try {
        this.saveLoading = true;
        const res = await this.svc.updateCourseReservableLicenseTypes(this.$route.query.course_id, value || []);
        this.optionsSelected = res.reservable_license_types || null;
        this.saveLoading = false;
      } catch (e) {
        this.saveLoading = false;
      }
    },
  },

  components: {
    confirmationDialog: () => import('@/components/global/ConfirmationDialog.vue')
  }
}
</script>

<style lang="scss" scoped>
.reservation-options {
  margin: 12px auto 8px auto;
  width: 152px;

  &::v-deep {
    .v-label {
      font-size: 14px;
    }
  }

  &__title {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 4px;
    color: #5C6E77;
  }
}

</style>