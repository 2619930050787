import { render, staticRenderFns } from "./CMReservationOptionsSelector.vue?vue&type=template&id=0c9f95fa&scoped=true&lang=pug&"
import script from "./CMReservationOptionsSelector.vue?vue&type=script&lang=js&"
export * from "./CMReservationOptionsSelector.vue?vue&type=script&lang=js&"
import style0 from "./CMReservationOptionsSelector.vue?vue&type=style&index=0&id=0c9f95fa&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0c9f95fa",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
installComponents(component, {VCheckbox})
